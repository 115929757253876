<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    title="对接表单参数"
    width="100%"
    top="0vh">
    <div>
      <div style="width: 100%;float: left">
        <v-form-designer v-if="formRenderVisible" ref="vfDesignerRef" :designer-config="designerConfig"
                       :global-dsv="globalDsv">
          <template #customToolButtons>
            <el-button type="text" @click="printFormJson"><i class="el-icon-setting"></i>保存</el-button>
          </template>
        </v-form-designer>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false;formRenderVisible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        formData: {},
        optionData: {},
        dialogVisible: false,
        submitLoading: false,
        formRenderVisible: false,
        model: {
          id: "",
        },
        designerConfig: {
          externalLink: false,
          languageMenu: false,
          resetFormJson: false,
          importJsonButton: false,
          exportJsonButton: false,
          exportCodeButton: false,
          generateSFCButton: false,
          toolbarMaxWidth: 160,
        },

        //全局数据源变量
        globalDsv: {
          testApiHost: "http://www.test.com/api",
          testPort: 8080,
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        if (this.model.id) {
          this.formRenderVisible = true;
          this.$refs.vfDesignerRef.clearDesigner();
          this.$http.get("integrations/getVformJson/" + this.model.id).then(res => {
            this.$refs.vfDesignerRef.setFormJson(res.data);
          });
        }
      },
      printFormJson() {
        let params = {
          id: this.model.id,
          formParams: this.$refs.vfDesignerRef.getFormJson(),
        };
        this.$http.put("integrations/updateVformJson", params).then(res => {
          this.$message.success("保存成功");
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
