<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="model.id?'编辑对接':'新增对接'"
    width="700px"
    top="3vh"
    @close="$reset('form')">
    <div>
      <div style="width: 100%;float: left">
        <el-form
          ref="form"
          :model="model"
          class="form"
          label-width="80px">
          <el-form-item label="对接名称" prop="name" :rules="$rule.notNull">
            <el-input v-model="model.name" placeholder="城市、省份、厂商等"></el-input>
          </el-form-item>
          <el-form-item label="对接key" prop="integrationKey" :rules="$rule.notNull">
            <el-input v-model="model.integrationKey" placeholder="对接标识"></el-input>
          </el-form-item>
          <el-form-item label="所需参数">
            <el-button type="primary" @click="model.params.push('')">添加</el-button>
            <table class="vm-table" style="width: 100%;margin-top: 5px">
              <tr>
                <th>参数标识</th>
                <th style="width: 50px">操作</th>
              </tr>
              <!--eslint-disable-next-line-->
              <tr v-for="(item,index) in model.params">
                <td>
                  <el-input v-model="model.params[index]"></el-input>
                </td>
                <td>
                  <el-button type="danger" @click="model.params.splice(index, 1);">删除</el-button>
                </td>
              </tr>
            </table>
          </el-form-item>
          <el-form-item label="对接文档">
            <vm-upload-file
              v-model="model.doc.url"
              :filename.sync="model.doc.filename"
              accept=".zip,.doc,.docx,.pdf">
            </vm-upload-file>
          </el-form-item>
          <el-form-item label="平台接入文档">
            <vm-upload-file
              v-model="model.accessDoc.url"
              :filename.sync="model.accessDoc.filename"
              accept=".zip,.doc,.docx,.pdf">
            </vm-upload-file>
          </el-form-item>
          <el-form-item prop="showInHome" label="首页展示">
            <el-switch v-model="model.showInHome" :active-value="true" :inactive-value="false"></el-switch>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import VmUploadFile from "@/components/VmUploadFile";

  export default {
    components: {VmUploadFile},
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        model: {
          id: "",
          name: "",
          integrationKey: "",
          params: [],
          formParams: {},
          doc: {
            url: "",
            filename: "",
          },
          accessDoc: {
            url: "",
            filename: "",
          },
          showInHome: true,
        },
        dataTypeOptions: [
          {value: "text", label: "文本"},
          {value: "date", label: "时间"},
        ],
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        if (this.model.id) {
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("integrations/" + this.model.id)
          .then(({data}) => {
            this.$assign(this.model, data);
          });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("integrations", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
